:root {
  /*--q-shadow-color: rgba(0, 0, 0, 0.2);*/
  --q-button-color-faded: #0069d9;
  --q-button-color: #007bff;
  --q-title-background-color-faded: #4d565f;
  --q-title-background-color: #343a40;
  --q-choice-background-color: #54585e;
  --q-choice-background-color-faded: #989ea3;
}

.button:focus {
  outline: none;
}

.questionContainer {
  background-color: white;
  padding: 10px 0;
  margin: 0 auto;
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 0 8px var(--q-shadow-color);
  /*display: inline-block;*/
  transition: all 0.2s ease-in-out;
}

.button {
  font-size: 1.2rem;
  color: white;
  padding: 4px 16px;
  border: none;
  border-radius: 3px;
}

.blue {
  background-color: var(--q-button-color-faded);
}

.blue:hover {
  cursor: pointer;
  background-color: var(--q-button-color);
}

.right {
  float: right;
}

.questionContainer .title {
  margin: 0 auto;
  text-align: center;
  color: white;
  width: 100%;
  background-color: var(--q-title-background-color-faded);
  border-radius: 2px;
  padding: 15px 4px;
}

.questionContainer .title:hover {
  background-color: var(--q-title-background-color);
}

.vSpacer {
  margin-top: 30px;
}

.questionContainer .choices {
  text-align: center;
  display: flex;
  /* width: 500px; */
  max-width: 100%;
  margin: 0 auto;
  justify-content: space-around;
  padding: 0 40px;
}

.questionContainer .choice {
  padding: 4px 10px;
  margin: 0 2px;
  border-radius: 0;
  width: 36px;
  color: #007bff;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  height: 36px;
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
}

.questionContainer .choice:hover {
  cursor: pointer;
  background-color: #007bff;
  color: #ffffff;
}

.animate {
  transition: all 0.15s ease-in-out;
}

.hSpacer {
  width: 30px;
}

.questionContainer .marked {
  color: #ffffff;
  background-color: #007bff;
}
